/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import fields from '../../../../config/quoteShippingFields.json';
import useHandleFields from '../../../../hooks/useHandleFields';
import { useTypeProductService } from '../../../../hooks/useShippingSteps';
import { findObjectInArray } from '../../../../utils/filters';
import { UpdateUserAction } from '../../../../store/actions/auth.actions';
import { userName } from '../../../../utils/strings';
import { documentTypeOptions, typeDelivery } from '../../../../constants/general';

import Input from '../../../input';
import ModalTypeProduct from '../../../modalTypeProduct';
import InputEdit from '../../../inputEdit';
import Typography from '../../../typography';
import DirectionSelect, { collectionOrTakeToOfficeFunction } from '../directionSelect';
import RadioButton from '../../../radioButton';
import Select from '../../../select';
import Tooltip from '../../../tooltip';

import Warehouse from '../../../../assets/images/icons/warehouse-solid.svg';
import Truck from '../../../../assets/images/icons/truck-solid.svg';
import Info from '../../../../assets/images/icon-info.svg';
import tooltip from '../../../../assets/images/tooltip-gray.svg';
import EditIcon from '../../../../assets/images/edit_black.svg';

import '../columns.scss';

const Right = ({
  user, optionsProductTypeArray, onChange, UpdateFunction, errorsList,
  isMobileDevice, shippingData, children, deliverySelected,
}) => {
  let timer;
  const [listFields, setListFields] = useState([]);
  const [showDirectionSelect, setShowDirectionSelect] = useState(false);
  const [productDescription, setProductDescription] = useState('');
  const [collectionOrTakeToOffice, setCollectionOrTakeToOffice] = useState(null);
  const [showProductTypeList, setShowProductTypeList] = useState(false);
  const [productsTypeSelected, setProductsTypeSelected] = useState(
    optionsProductTypeArray.filter((productTypeArray) => productTypeArray.checked),
  );
  const [userAddress, setUserAddress] = useState(shippingData.expressAddress
    || user.alternativeDirections
    || user.address
    || [],
  );

  const existenceUserAgency = user?.agency ? user?.agency : null;

  const [formValues, updateFormValues] = useHandleFields({
    nameInTheGuide: user.businessName || userName(user.name, user.surname),
    senderCellPhone: user.cellPhone,
    senderAddress: shippingData.expressAddress || user.address,
    senderEmail: '',
    senderIdentificationNumber: '',
    senderIdentificationType: '',
    addressSending: '',
    productType: user.productType,
    recommendations: '',
  });

  const { listTypeProducts, getProductsService } = useTypeProductService();

  useEffect(() => {
    setListFields(fields.country[user.countryCode].fieldsInfoShipping
      .filter((item) => item.step === 1));
  }, [fields.country[user.countryCode]]);

  useEffect(() => {
    Object.keys(shippingData).forEach((key) => {
      updateFormValues(key, shippingData[key] || productDescription || '');
    });
  }, [shippingData]);

  useEffect(() => {
    if (!Array.isArray(userAddress)) {
      const tempUser = { ...user };
      if (!shippingData.expressAddress
        && !tempUser.alternativeDirections
        && user.address) {
        tempUser.alternativeDirections = [{ name: user.address, address: user.address }];
      }
      setUserAddress(tempUser.alternativeDirections);
      UpdateFunction(tempUser);
    }
  }, [userAddress]);

  const [optionsProductType, setOptionsProductType] = useState(optionsProductTypeArray);

  const changeToCheck = ({ product, checked }) => {
    const temporalProductTypeSelected = productsTypeSelected.slice();

    const tempProductTypeArray = optionsProductTypeArray.map((tempProduct) => {
      const tempProductReturned = tempProduct;

      if (tempProduct.value === product.value && temporalProductTypeSelected.length < 3) {
        tempProductReturned.checked = checked;
        temporalProductTypeSelected.push(tempProductReturned);
      } else if (tempProduct.value === product.value && product.checked) {
        tempProductReturned.checked = checked;
      }

      setProductsTypeSelected(
        temporalProductTypeSelected.filter((productSelected) => productSelected.checked),
      );
      return tempProductReturned;
    });
    setOptionsProductType(tempProductTypeArray);
  };

  useEffect(() => {
    onChange({
      formValues,
      collectionOrTakeToOffice,
      productDescription,
      listTypeProducts,
    });
  }, [formValues]);

  useEffect(() => {
    const productSelectTypeDescription = productsTypeSelected.map((x) => x.text).join('-');
    const productSelectType = productsTypeSelected.map((x) => x.value).join('-');
    setProductDescription(productSelectTypeDescription);
    updateFormValues('productType', productSelectType);

    const tempUser = { ...user };
    tempUser.productType = productSelectType;
    UpdateFunction(tempUser);
  }, [productsTypeSelected]);

  useEffect(() => {
    if (deliverySelected.officeAddress && !deliverySelected.pickupService) {
      updateFormValues('senderAddress', `Lo llevaré a la ${typeDelivery[user.countryCode]}`);
    }
  }, []);
  const changesDirectionSelected = ({
    selectedAddress, CollectionOrTakeToOffice,
  }) => {
    updateFormValues('senderAddress', selectedAddress);
    setCollectionOrTakeToOffice(CollectionOrTakeToOffice);
  };

  useEffect(() => {
    if (collectionOrTakeToOffice === 'takeToOffice') {
      updateFormValues('senderAddress', `Lo llevaré a la ${typeDelivery[user.countryCode]}`);
    }
  }, [collectionOrTakeToOffice]);
  const updateUserAddress = () => {
    const tempUser = { ...user };
    if (productDescription) {
      tempUser.productType = productDescription;
      UpdateFunction(tempUser);
    }
  };

  const handleContainerField = (name, typeField) => {
    if (shippingData.expressAddress) {
      setCollectionOrTakeToOffice(null);
      setUserAddress(shippingData.expressAddress);
      updateFormValues('senderAddress', shippingData.expressAddress);
    }

    if (typeField === 'radioButton') {
      collectionOrTakeToOfficeFunction(setCollectionOrTakeToOffice);
    }

    if (name === 'senderAddress') {
      setShowDirectionSelect(true);
    }
  };

  const handleProductType = (name) => name === 'productType' && setShowProductTypeList(!showProductTypeList);

  const itemsFieldsFiltered = (item) => (!deliverySelected.pickupService
    && deliverySelected.officeAddress
    ? !item.isAgency && !item.isLastItems && item.name !== 'senderAddress'
    : !item.isAgency && !item.isLastItems);

  const showLabel = (label, name) => {
    if (name === 'senderAddress') {
      return isMobileDevice ? label : 'Dirección de recogida:';
    }
    return label;
  };

  const filtersTypeProducts = async (value, name) => {
    const serviceParams = {};

    if (user.countryCode === '484' && name === 'productType') {
      if (/^\d+$/.test(value)) {
        serviceParams.code = value;
      }

      if (/^[a-zA-Z]+$/.test(value)) {
        serviceParams.description = value;
      }
      await getProductsService(serviceParams);
    }
  };
  const showList = () => listTypeProducts && listTypeProducts.map((it) => `${it.code}-${it.description}`);

  const valueField = (name) => (user.countryCode === '170' ? name === 'productType' ? productDescription : formValues[name] : formValues[name]);

  const findError = (name) => findObjectInArray(name, errorsList, 'name')?.error ?? null;

  return (
    <>
      <div className={`sender-info-general ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="sender-info">
          <div className="title">
            <Typography
              text="Información del remitente"
              type="title"
            />
          </div>
          {listFields
            .filter((item) => itemsFieldsFiltered(item))
            .map(({
              label, name, type, typeField, valueRadioButton,
            }) => (
              <div
                className={`address ${typeField === 'radioButton' ? 'address' : ''} ${name === 'senderAddress' ? 'cursor-pointer' : ''}`}
                onClick={() => handleContainerField(name, typeField)}
                onKeyUp={() => handleContainerField(name, typeField)}
                key={name}
                role="button"
                tabIndex={0}
              >
                {typeField === 'input' ? (
                  <InputEdit
                    label={showLabel(label, name)}
                    value={shippingData.nameInTheGuide
                      ? formValues[{ name: shippingData.nameInTheGuide }]
                      : formValues[name]}
                    type={type}
                    onChange={(value) => !shippingData.nameInTheGuide
                      && updateFormValues(name, value)}
                    annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                    edit
                    classNameInput="text-end"
                    iconLeft={name === 'senderAddress' && Truck}
                  />
                ) : (
                  <RadioButton
                    name={name}
                    text={label}
                    value={valueRadioButton}
                    onChange={setCollectionOrTakeToOffice}
                    className="text-end2"
                    checked={
                      !deliverySelected.pickupService && deliverySelected?.officeAddress
                        ? valueRadioButton === 'takeToOffice'
                        : collectionOrTakeToOffice === valueRadioButton
                    }
                    iconLeft={Warehouse}
                  />
                )}
              </div>
            ))}
          {!deliverySelected.pickupService
            && deliverySelected.officeAddress && (
              <div className="office-address-info">
                <img src={Info} alt="info" />
                <span className="office-address-info__text">
                  Envío sin recolección, recuerda llevar el paquete a una
                  sucursal de la paquetería.
                </span>
              </div>
          )}
          {showDirectionSelect && (
            <div className="direction-select-container">
              <DirectionSelect
                directions={userAddress}
                onChange={changesDirectionSelected}
                hideElement={setShowDirectionSelect}
              />
            </div>
          )}
        </div>
        {
          existenceUserAgency && (
            <div>
              {listFields.filter(({ isAgency }) => isAgency).map(({
                label, name, type, typeField, placeholder,
              }) => (
                <div key={name}>
                  {typeField === 'input' ? (
                    !isMobileDevice ? (
                      <InputEdit
                        label={label}
                        value={formValues[name]}
                        type={type}
                        onChange={(value) => updateFormValues(name, value)}
                        edit
                        annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                        classNameInput="text-end"
                      />
                    ) : (
                      <Input
                        label={label}
                        placeholder={placeholder}
                        value={formValues[name]}
                        onChange={(value) => updateFormValues(name, value)}
                        annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                      />
                    )
                  ) : (
                    <>
                      {name === 'senderIdentificationType' && (
                        <Typography
                          text="Tipo de documento"
                          className="label-document"
                          type=""
                        />
                      )}
                      <Select
                        label={label}
                        value={formValues[name]}
                        options={documentTypeOptions}
                        onChange={(value) => updateFormValues(name, value)}
                        startingPosition="top-left"
                        annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          )
        }
        {listFields.filter(({ isLastItems }) => isLastItems).map(({
          name, label, placeholder, type, annotation, icon,
        }) => (
          <div
            key={name}
            className={name === 'productType' ? 'product-description' : 'recommendations'}
          >
            <button
              type="button"
              onClick={() => handleProductType(name)}
              className="fake-tap-button"
            />
            <Input
              label={label}
              placeholder={placeholder}
              type={type}
              value={valueField(name)}
              onChange={(value) => {
                updateFormValues(name, value);
                clearTimeout(timer);
                if (name === 'productType' && value.trim() !== '') {
                  timer = setTimeout(() => {
                    filtersTypeProducts(value, name);
                  }, 700);
                }
              }}
              svgClasscss={user.countryCode === '484' && icon}
              changeColorsvg={user.countryCode === '484' && name === 'productType'}
              disabled={user.countryCode === '170' && name === 'productType'}
              iconRigth={(name === 'productType' && user.countryCode === '170') && EditIcon}
              onClickIconRight={() => handleProductType(name)}
              annotationError={!valueField(name) ? findError(name) : null}
              dataList={name === 'productType' && showList()}
              notCharacterAnnotation={annotation}
              childrenRigth={name === 'productType' && user.countryCode === '484' && (
                <Tooltip
                  overlayText="Debes seleccionar una opción, esto es requisito del Sistema de Administración Tributaria (SAT)"
                  startingPosition="bottom-rigth"
                  iconTooltip={tooltip}
                />
              )}
            />
          </div>
        ))}
        {children}
      </div>
      {user.countryCode === '170' && showProductTypeList && (
        <ModalTypeProduct
          handleSave={() => { setShowProductTypeList(false); updateUserAddress(); }}
          optionsProductType={optionsProductType}
          closeModal={() => setShowProductTypeList(false)}
          changeToCheck={changeToCheck}
        />
      )}
    </>
  );
};

Right.propTypes = {
  user: PropTypes.shape({
    address: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
    cellPhone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    alternativeDirections: PropTypes.arrayOf(PropTypes.shape({})),
    agency: PropTypes.shape({}),
    productType: PropTypes.string,
  }).isRequired,
  deliverySelected: PropTypes.shape({
    deliveryCompanyId: PropTypes.string.isRequired,
    collectionCommissionWithRate: PropTypes.number,
    shippingCost: PropTypes.number.isRequired,
    collectionCommissionWithOutRate: PropTypes.number,
    shippingRealCost: PropTypes.number,
    shippingTime: PropTypes.number.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    deliveryCompanyImgUrl: PropTypes.string.isRequired,
    officeAddress: PropTypes.string.isRequired,
    pickupService: PropTypes.bool.isRequired,
  }).isRequired,
  shippingData: PropTypes.shape({
    expressAddress: PropTypes.string,
    nameInTheGuide: PropTypes.string,
  }).isRequired,
  optionsProductTypeArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  UpdateFunction: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

Right.defaultProps = {
  children: null,
};
const mapDispatchToProps = (dispatch) => ({
  UpdateFunction: bindActionCreators(UpdateUserAction, dispatch),
});

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Right);
