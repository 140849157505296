/* eslint-disable max-len,no-unsafe-optional-chaining */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import CheckBox from 'components/checkBox';
import DataCollectionCommisionDetails from 'components/dataCollectionCommisionDetails';
import Onboarding from 'components/onboarding';
import Typography from 'components/typography';
import {
  finalPrice,
  collectionServiceValue,
  collectionServiceCommision,
  functionDiscount,
  discountCalculateValue,
} from 'utils/prices';
import { toCurrencyCop } from 'utils/strings';

import { valueChangeCommissionRate } from 'utils/quoteShipping';
import DeliveryCardToList from '../deliveryCardToList';

import '../../stepsQuoteShipping.scss';

const Right = ({
  deliveriesList,
  addShippingToCollectionValue,
  collectionService,
  setAddShippingToCollectionValue,
  next,
  isMobileDevice,
  saleValue,
  deliveryCompaniesConfig,
  user,
  continueToLastDetails,
}) => {
  const [deliverySelected, setDeliverySelected] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(null);
  const [showDataCollectionCommissionDetails, setShowDataCollectionCommissionDetails] = useState(false);

  useEffect(() => {
    setTimeout(() => (
      setShowOnboarding(true)
    ), 4000);
  }, []);

  useEffect(() => {
    if (showDataCollectionCommissionDetails) {
      setShowDataCollectionCommissionDetails(true);
    }
  }, [showDataCollectionCommissionDetails]);

  const detailsCost = () => {
    if (deliverySelected) {
      return (
        <>
          <div
            className="collection-value color-blue"
          >
            <span>
              Tarifa calculada para recaudo:
              {toCurrencyCop(
                user,
                collectionServiceValue(
                  saleValue,
                  deliverySelected.shippingCost,
                  collectionService,
                  addShippingToCollectionValue,
                  deliverySelected.collectionCommissionWithRate,
                  deliverySelected.collectionCommissionWithOutRate,
                ),
              )}
            </span>
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Envío:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(user, deliverySelected.shippingCost)
              )}
            />
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Comision de recaudo:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(
                  user,
                  collectionServiceCommision(
                    collectionService,
                    addShippingToCollectionValue,
                    deliverySelected.collectionCommissionWithRate,
                    deliverySelected.collectionCommissionWithOutRate,
                  ),
                )
              )}
            />
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Total:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(
                  user,
                  finalPrice(
                    deliverySelected.shippingCost,
                    collectionService,
                    addShippingToCollectionValue,
                    deliverySelected.collectionCommissionWithRate,
                    deliverySelected.collectionCommissionWithOutRate,
                  ),
                )
              )}
            />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div
      id={isMobileDevice ? 'deliveries-list-quote-shipping' : 'deliveries-list-quote-shipping-mobile'}
      className={`${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${collectionService ? 'list-with-checkbox-bottom' : ''}`}
    >
      {!user && showOnboarding && !deliverySelected && (<Onboarding />)}
      <div className="container-list__deliveries-list-quote-shipping">
        {deliveriesList && deliveriesList.length > 0 && (
          <>
            {deliveriesList.map((delivery) => (
              <React.Fragment key={`item-${delivery.id}`}>
                <DeliveryCardToList
                  user={user}
                  isExpressService={delivery.isExpressService}
                  collectionService={collectionService}
                  id={delivery.deliveryCompanyId}
                  deliveryName={delivery.deliveryCompanyName}
                  deliveryImg={delivery.deliveryCompanyImgUrl}
                  deliveryTime={delivery.shippingTime}
                  shippingValue={
                    finalPrice(
                      delivery.shippingCost,
                      collectionService,
                      addShippingToCollectionValue,
                      delivery.collectionCommissionWithRate,
                      delivery.collectionCommissionWithOutRate,
                    )
                  }
                  shippingRealValue={functionDiscount(
                    delivery, collectionService, addShippingToCollectionValue, deliveryCompaniesConfig,
                  )}
                  collectionValue={
                    collectionServiceValue(
                      saleValue,
                      delivery.shippingCost,
                      collectionService,
                      addShippingToCollectionValue,
                      delivery.collectionCommissionWithRate,
                      delivery.collectionCommissionWithOutRate,
                    )
                  }
                  deliveryScore={delivery.score || 0}
                  onClick={() => {
                    setDeliverySelected(delivery);
                    next(delivery);
                    continueToLastDetails();
                  }}
                  setShowDetails={() => setDeliverySelected(delivery)}
                  setHideDetails={() => setDeliverySelected(null)}
                  detailsCostElement={detailsCost}
                  isMobileDevice={isMobileDevice}
                  focused={deliverySelected && deliverySelected.id === delivery.id}
                  details={false}
                  routeType={delivery.routeType}
                  pickupService={delivery.pickupService}
                  officeAddress={delivery.officeAddress}
                  delivery={deliverySelected}
                  showDataCollectionCommissionDetails={showDataCollectionCommissionDetails}
                  setShowDataCollectionCommissionDetails={() => {
                    setDeliverySelected(delivery);
                    setShowDataCollectionCommissionDetails(true);
                  }}
                  collectionCommissionValueDiscount={delivery?.realCollectionCommissionWithoutRateValue && (discountCalculateValue(
                    valueChangeCommissionRate(delivery, addShippingToCollectionValue).realCommissionValue,
                    valueChangeCommissionRate(delivery, addShippingToCollectionValue).collectionCommissionRate,
                    true,
                  ))}
                  realValueCollectionCommission={delivery?.realCollectionCommissionWithoutRateValue
                    ? valueChangeCommissionRate(delivery, addShippingToCollectionValue).realCommissionValue
                    : valueChangeCommissionRate(delivery, addShippingToCollectionValue).collectionCommissionRate}
                  insuranceValue={delivery.insuranceValue}
                  collectionCommissionWithOutRate={valueChangeCommissionRate(delivery, addShippingToCollectionValue).collectionCommissionRate}
                  discountFreightValue={delivery.shippingRealCost && (discountCalculateValue(
                    delivery.shippingCost,
                    delivery.shippingRealCost,
                    true,
                  ))}
                  shippingCost={delivery.shippingRealCost || delivery.shippingCost}
                  collectionCommissionValue={valueChangeCommissionRate(delivery, addShippingToCollectionValue).collectionCommissionRate}
                />
              </React.Fragment>
            ))}
          </>
        )}
        {collectionService && (
          <div className="checkbox-add-shipping-to-collection-value">
            <CheckBox
              checked={addShippingToCollectionValue}
              onChange={setAddShippingToCollectionValue}
              text="Quiero que mi destinatario también pague el envío"
            />
          </div>
        )}
        {showDataCollectionCommissionDetails && isMobileDevice && (
          <DataCollectionCommisionDetails
            user={user}
            handleClose={() => setShowDataCollectionCommissionDetails(false)}
            commissionCollectionValue={deliverySelected?.realCollectionCommissionWithoutRateValue
              ? valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).realCommissionValue
              : valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).collectionCommissionRate}
            discountCommissionCollectionValue={deliverySelected?.realCollectionCommissionWithoutRateValue && (discountCalculateValue(
              valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).collectionCommissionRate,
              valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).realCommissionValue,
              true,
            ))}
            discountFreightValue={deliverySelected?.shippingRealCost && (discountCalculateValue(
              deliverySelected?.shippingCost,
              deliverySelected?.shippingRealCost,
              true,
            ))}
            freightValue={(deliverySelected.shippingRealCost || deliverySelected.shippingCost) - deliverySelected.insuranceValue}
            insuranceValue={deliverySelected.insuranceValue}
            titleCollectionService="Descuento en servicio de recaudo"
            titleDiscountShipping="Descuento en envío"
            isMobileDevice={isMobileDevice}
          />
        )}
      </div>
    </div>
  );
};

Right.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  deliveriesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  collectionService: PropTypes.bool.isRequired,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  saleValue: PropTypes.number,
  deliveryCompaniesConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}),
  continueToLastDetails: PropTypes.func.isRequired,
};

Right.defaultProps = {
  saleValue: null,
  user: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Right);
