import { wompi, wompiPublicKey, wompiInte } from '../../../../config/nocompanyconfig.fron.json';
import { getWompi } from '../../../../services/wompi';

export const getTokenAndDocument = async () => {
  try {
    const endPoint = `${wompi.urlBase}/${wompi.tokenEndpoint}/${wompiPublicKey}`;
    const data = await getWompi(endPoint);

    return {
      acceptance_token: data?.presigned_acceptance?.acceptance_token,
      documentLink: data?.presigned_acceptance?.permalink,
    };
  } catch (error) {
    return error;
  }
};

export const getWompiInt = () => wompiInte;
