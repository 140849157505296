import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import AmountDiscount from 'components/amountDiscount';
import DeliveryCompaniesObservations from 'components/deliveryCompaniesObservations';
import PickupTag from 'components/pickupTag';
import { toCurrencyCop } from 'utils/strings';
import DeliveryTime from 'constants/deliveryTime';
import DeliveryValue from 'constants/deliveryValue';
import DeliveryImage from 'constants/handleImageClick';

import IconArrowDown from 'assets/images/arrow-down-gray.svg';
import IconArrowUp from 'assets/images/arrow-up-gray.svg';
import IconStar from 'assets/images/star.svg';

import './cardMobileCollectionService.scss';

const CardMobileCollectionService = ({
  id,
  deliveryName,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  deliveryScore,
  routeType,
  pickupService,
  officeAddress,
  routeTypeValidation,
  onClick,
  showDataCollectionCommissionDetails,
  setShowDataCollectionCommissionDetails,
  realValueCollectionCommission,
  collectionCommissionValue,
  collectionCommissionWithOutRate,
  focused,
  isExpressService,
}) => {
  const isMobileDevice = useSelector(
    (state) => state.mediaQuery.isMobileDevice,
  );
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state?.country || null);
  const [showDeliveryAnnottation, setShowDeliveryAnnottation] = useState(false);

  return (
    <div className="container__content-info-delivery-companies">
      <button
        type="button"
        className="content-top__card-delivery-list"
        onClick={() => onClick()}
      >
        <div className="container-delivery-info">
          <DeliveryImage
            deliveryImg={deliveryImg}
            deliveryName={deliveryName}
            setShowDeliveryAnnottation={setShowDeliveryAnnottation}
          />
          <div className="content-delivery-info">
            <div className="top">
              <span className="delivery-name">{deliveryName}</span>
              <div className="score">
                <img src={IconStar} alt="score-img" height="16px" />
                <p>{deliveryScore}</p>
              </div>
            </div>
            <div className="delivery-score">
              {countryCode === '170' ? (
                <>
                  <div className="routeType">
                    {routeTypeValidation(routeType)}
                  </div>
                  {isExpressService && (
                  <div className="routeType-express">
                    Express
                  </div>
                  )}
                </>
              ) : (
                <>
                  {pickupService && <PickupTag pickup={pickupService} />}
                  {officeAddress && <PickupTag pickup={false} />}
                </>
              )}
            </div>
          </div>
        </div>
        <DeliveryTime deliveryTime={deliveryTime} />
      </button>
      <button
        className={`${shippingRealValue ? 'shipping-real-value' : 'shipping-value'}
         ${showDataCollectionCommissionDetails && focused ? 'active' : 'inactive'}`}
        onClick={() => setShowDataCollectionCommissionDetails()}
        type="button"
      >
        {shippingRealValue && (
          <>
            <div>
              { shippingValue === shippingRealValue ? (
                <span className="content-delivery-info__value-collection">
                  {toCurrencyCop(user, Math.floor(shippingValue))}
                </span>
              ) : (
                <DeliveryValue
                  shippingValue={shippingValue}
                  shippingRealValue={shippingRealValue}
                  user={user}
                  classContainer="shipping-real-value__delivery"
                />
              )}
              <AmountDiscount
                user={user}
                realValue={realValueCollectionCommission}
                valueDiscount={collectionCommissionValue}
                isMobileDevice={isMobileDevice}
                collectionCommissionWithOutRate={collectionCommissionWithOutRate}
              />
            </div>
            <button
              className="icon-arrow-down"
              type="submit"
            >
              <img
                src={(focused && showDataCollectionCommissionDetails) ? IconArrowUp : IconArrowDown}
                alt="arrow"
              />
            </button>
          </>
        )}
      </button>
      {showDeliveryAnnottation && (
        <DeliveryCompaniesObservations
          deliveryCompanyImgUrl={deliveryImg}
          deliveryCompanyName={deliveryName}
          deliveryCompanyId={id}
          close={() => setShowDeliveryAnnottation(false)}
          isMobileDevice={isMobileDevice}
          countryCode={user?.countryCode || countryCode}
        />
      )}
      <hr />
    </div>
  );
};

CardMobileCollectionService.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
  routeTypeValidation: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showDataCollectionCommissionDetails: PropTypes.bool.isRequired,
  setShowDataCollectionCommissionDetails: PropTypes.func.isRequired,
  realValueCollectionCommission: PropTypes.number,
  collectionCommissionWithOutRate: PropTypes.number,
  collectionCommissionValue: PropTypes.number,
  focused: PropTypes.bool.isRequired,
  isExpressService: PropTypes.bool,
};

CardMobileCollectionService.defaultProps = {
  shippingRealValue: null,
  officeAddress: null,
  realValueCollectionCommission: null,
  collectionCommissionValue: null,
  collectionCommissionWithOutRate: null,
  isExpressService: false,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(CardMobileCollectionService);
