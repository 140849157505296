/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { compose } from 'redux';

import { getUsersAddresses } from '../../../../../services/user';
import { filterByName, findLocationByName, findObjectInArray } from '../../../../../utils/filters';
import { routeExistenceValidationFunction, stepOneValidation } from '../../../../../utils/validation/validationsQuoteShipping';
import { useValidationCountry, useFetchIpData } from '../../../../../utils/validationCountry';
import { getLocationsService } from '../../../../../services/location';
import ModalCreateAddress from '../../../../myAddressesScreen/myAddressesSettingsViewer/modalCreateAddress';

import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import Typography from '../../../../../components/typography';

import IconSearch from '../../../../../assets/images/search.svg';

import './stepOneExpressShipping.scss';

const StepOneExpressShipping = ({
  shippingData, errorList, setErrorList, next, user,
}) => {
  const locations = useSelector((state) => state.globalData.locations || []);
  const [origin, setOrigin] = useState('');
  const [destiny, setDestiny] = useState('');
  const [destinyCode, setDestinyCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDirections, setShowDirections] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [listAddress, setListAddress] = useState([]);
  const [addressSelected, setAddressSelected] = useState({});
  const [userLocation, setUserLocation] = useState(addressSelected.locationCode);
  const [listAddressFiltered, setListAddressFiltered] = useState([]);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const getAddressesService = async () => {
    // eslint-disable-next-line no-return-await
    const getAddresses = async () => await getUsersAddresses();
    const addressestoMap = await getAddresses();
    setListAddress(addressestoMap);
  };

  const getLocationService = async (locationCode) => {
    const getLocation = await getLocationsService();
    const locationFilter = getLocation.filter(
      (location) => location.locationCode === locationCode,
    )[0];
    setUserLocation(`${locationFilter.locationName}-${locationFilter.departmentOrStateName}`);
  };

  useEffect(() => {
    getAddressesService()
      .catch((err) => err);
    getLocationService(addressSelected.locationCode)
      .catch((err) => err);
  }, []);

  useEffect(() => {
    setOrigin(shippingData.origin || '');
    setDestiny(shippingData.destiny || '');
  }, [shippingData]);

  useEffect(() => {
    const destinySplitted = destiny.split('-');
    if (destinySplitted.length > 1) {
      const destinyObject = findLocationByName(
        destinySplitted[0], destinySplitted[1], locations,
      );
      setDestinyCode(destinyObject?.locationCode ?? '');
    }
  }, [origin, destiny]);

  const beforeNext = async () => {
    setLoading(true);
    await routeExistenceValidationFunction(
      user.countryCode,
      addressSelected?.locationCode, destinyCode,
      () => {
        next(
          addressSelected?.name,
          addressSelected?.locationCode,
          destiny,
          destinyCode,
          addressSelected.address,
          addressSelected.interCode,
        );
      },
    );
    setLoading(false);
  };
  const getLocations = (item) => {
    setAddressSelected(item);
    setShowDirections(false);
    getLocationService(item.locationCode || '')
      .catch((err) => err);
  };

  useEffect(() => {
    if (listAddress && addressSelected.name) {
      setListAddressFiltered(filterByName(addressSelected.name, listAddress));
    } else if (addressSelected.name?.length === 0 || !addressSelected.name) {
      setListAddressFiltered(listAddress);
    }
  }, [addressSelected.name, listAddress]);

  return (
    <div className="contentStepQuoteShippingExpress">
      <section className="content stepOneExpress">
        <div className="contentImput">
          <Input
            label="Sede de origen"
            iconRigth={IconSearch}
            value={addressSelected.name}
            onFocus={() => setShowDirections(true)}
            annotationError={findObjectInArray('origin', errorList, 'name')?.error ?? null}
            placeholder="Dirección predeterminada"
            changeColorsvg
            svgClasscss="magnifying-glass"
            onChange={(value) => {
              setAddressSelected({ ...addressSelected, name: value });
            }}
          />
          {
            showDirections && (

            <div className="description">
              <div className="scroll">
                {
                    user.address && (
                      <button
                        type="button"
                        className="address-select"
                        name="alternatives-directions"
                        onClick={getLocations}
                      >
                        <div className="address-select-hover" />
                        <div className="adress-content">
                          <span className="default-address">Dirección predeterminada</span>
                          <span className="address-subtitle">{user.address}</span>
                        </div>
                      </button>
                    )
                  }
                {
                    listAddressFiltered && listAddressFiltered.map((item) => (
                      <button
                        type="button"
                        key={item._id}
                        className="address-select"
                        name="alternatives-directions"
                        onClick={() => getLocations(item)}
                      >
                        <div className="address-select-hover" />
                        <div className="adress-content">
                          <span className="default-address">{item.name}</span>
                          <span className="address-subtitle">{item.address}</span>
                        </div>
                      </button>
                    ))
                  }
              </div>
              <button
                type="button"
                className="create-address-button"
                onClick={() => setShowModal(true)}
              >
                <div>
                  <span>Crear nueva dirección</span>
                </div>
              </button>
            </div>
            )
          }
          <section className="containerAddresses">
            <div>
              <Typography
                text="Ciudad de origen"
                className="title"
              />
              <Typography
                text={userLocation || 'Sin ciudad origen'}
                className="subtitle"
              />
            </div>
            <div>
              <Typography
                text="Dirección de recogida"
                className="title"
              />
              <Typography
                text={addressSelected.address || 'Sin dirección'}
                className="subtitle"
              />
            </div>
          </section>
        </div>
        <div className="contentImput">
          <Input
            label="¿Cuál es la ciudad de destino?"
            iconRigth={IconSearch}
            dataList={locations.map((location) => `${location.locationName}-${location.departmentOrStateName}`)}
            value={destiny}
            onFocus={() => setShowDirections(false)}
            onChange={(value) => {
              setDestiny(value);
              if (findObjectInArray('destiny', errorList, 'name')) {
                stepOneValidation(errorList, setErrorList, value, locations);
              }
            }}
            onBlur={(e) => {
              stepOneValidation(errorList, setErrorList, e.target.value, locations);
            }}
            annotationError={findObjectInArray('destiny', errorList, 'name')?.error || null}
            placeholder="MEDELLÍN-ANTIOQUIA"
            changeColorsvg
            svgClasscss="magnifying-glass"
          />
        </div>
      </section>
      <section className="containerButtonsStepsQuoteShippingExpress">
        <Button
          text="Continuar"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          disabled={
            !addressSelected?.locationCode
            || !destinyCode
          }
          loading={loading}
          onClick={beforeNext}
        />
      </section>
      {
        showModal && (
          <ModalCreateAddress
            closeAndRemove={() => {
              setShowModal(false);
            }}
            setShowCreateAddressModal={() => {
              setShowModal(false);
            }}
            user={user}
            onChangeListAddress={setListAddress}
          />
        )
      }
    </div>
  );
};

StepOneExpressShipping.propTypes = {
  shippingData: PropTypes.shape({
    origin: PropTypes.string,
    originCode: PropTypes.string,
    destiny: PropTypes.string,
    destinyCode: PropTypes.string,
    interCode: PropTypes.string,
  }).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

StepOneExpressShipping.propTypes = {
  user: PropTypes.any,
};

StepOneExpressShipping.defaultProps = {
  user: {},
};

const mapStateToProps = (state) => ({
  user: (state.auth) ? state.auth?.user : {},
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(StepOneExpressShipping);
