const validCountryCodes = ['170', '152'];

export const validateOriginLocation = (
  countryCode,
  originDaneCode,
  originName,
  originDepName,
) => {
  if (validCountryCodes.includes(countryCode)) {
    return `${originName}-${originDepName}`;
  }
  return `${originDaneCode}-${originName}-${originDepName}`;
};

export const validateDestinyLocation = (
  countryCode,
  destinyDaneCode,
  destinyName,
  destinyDepName,
) => {
  if (validCountryCodes.includes(countryCode)) {
    return `${destinyName}-${destinyDepName}`;
  }
  return `${destinyDaneCode}-${destinyName}-${destinyDepName}`;
};

export const validateComments = (comment) => (comment === 'NA' ? '' : comment);
