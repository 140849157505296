import swal from 'sweetalert';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { encriptData, setCurrentUser } from '../../utils/auth';
import { SaveTemporalDataAction, SignInAction, UpdateTokenAction } from '../../store/actions/auth.actions';
import { loginSocialAccountService, validateEmailService } from '../../services/user';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';

import GoogleIcon from '../../assets/images/google.svg';

import './buttonsSocialMedia.scss';
import { operationsUrl } from '../../constants';

const ButtonsSocialMedia = ({ className, urlToRedirect, remember }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [executingAction, setExecutingAction] = useState(false);

  const searchEmailAndLoginOrRegister = async ({
    email, accessToken, socialUserId, socialNetwork,
  }) => {
    try {
      setExecutingAction(true);
      const response = await validateEmailService(email);
      if (response.status === 200) {
        const loginResponse = await loginSocialAccountService({
          accessToken, socialUserId, socialNetwork,
        });
        loginResponse.user.accessToken = accessToken;
        loginResponse.user.socialUserId = socialUserId;
        loginResponse.user.socialNetwork = socialNetwork;
        if (urlToRedirect) {
          const tokenEncrypt = await encriptData(loginResponse);
          redirectToOtherPlatforms({ urlToRedirect, token: tokenEncrypt, keep: remember });
          return null;
        }
        await setCurrentUser(loginResponse, true);
        dispatch(UpdateTokenAction(encriptData(loginResponse)));
        dispatch(SignInAction(loginResponse.user));
        redirectToOtherPlatforms({ urlToRedirect: `${operationsUrl}/buscar-codigo-mp`, keep: remember });
      } else navigate('/registro?step=socialAccount');
    } catch {
      swal({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error, inténtelo de nuevo.',
        button: 'Intentar de nuevo',
      });
    } finally {
      setExecutingAction(false);
    }
    return null;
  };

  const responseGoogle = (response) => {
    dispatch(SaveTemporalDataAction({
      email: response.profileObj.email || '',
      name: response.profileObj.name || '',
      cellPhone: response.profileObj.cellPhone || '',
      accessToken: response.tokenId || '',
      socialUserId: response.googleId || '',
      socialNetwork: 'google',
    }));
    searchEmailAndLoginOrRegister({
      email: response?.profileObj?.email,
      accessToken: response.tokenId || '',
      socialUserId: response.googleId || '',
      socialNetwork: 'google',
    });
  };

  return (
    <div className={['socialLogin', className].join('')}>
      <GoogleLogin
        type="icon"
        clientId="694765150284-3o0rj6obtp1h1fmuqrrtv8qjoobi8hq7.apps.googleusercontent.com"
        render={(e) => (
          <div className={['google', executingAction ? 'disabled' : ''].join(' ')} onClick={() => e.onClick()}>
            <img className="icon" src={GoogleIcon} alt="google" />
            <span>Google</span>
          </div>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy="single_host_origin"
      />
    </div>
  );
};

ButtonsSocialMedia.propTypes = {
  className: PropTypes.string,
  urlToRedirect: PropTypes.string,
  remember: PropTypes.bool.isRequired,
};

ButtonsSocialMedia.defaultProps = {
  className: '',
  urlToRedirect: null,
};

export default ButtonsSocialMedia;
