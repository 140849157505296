/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AmountDiscount from 'components/amountDiscount';
import DeliveryCompaniesObservations from 'components/deliveryCompaniesObservations';
import PickupTag from 'components/pickupTag';
import DeliveryTime from 'constants/deliveryTime';
import DeliveryValue from 'constants/deliveryValue';
import DeliveryImage from 'constants/handleImageClick';
import { capitalizeWords, toCurrencyCop } from 'utils/strings';

import IconDropUp from 'assets/images/Dropup_black.svg';
import IconStar from 'assets/images/star.svg';

import '../deliveryCardToList.scss';

const DeliveryCardToList = ({
  id,
  deliveryName,
  isExpressService,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  collectionValue,
  deliveryScore,
  delivery,
  details,
  routeType,
  pickupService,
  officeAddress,
  routeTypeValidation,
  handleShowDetail,
  realValueCollectionCommission,
  collectionCommissionValue,
  collectionCommissionWithOutRate,
}) => {
  const isMobileDevice = useSelector(
    (state) => state.mediaQuery.isMobileDevice,
  );
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state?.country || null);
  const [showDeliveryAnnottation, setShowDeliveryAnnottation] = useState(false);

  const conditionShowDeliveryName = (name) => {
    if (name !== 'TCC') {
      return capitalizeWords(name);
    }

    return name;
  };
  return (
    <div className="container__content-top-and-content-bottom">
      <div className="content-top__delivery-card-to-list">
        <div className="container-delivery-info">
          <DeliveryImage
            deliveryImg={deliveryImg}
            deliveryName={deliveryName}
            setShowDeliveryAnnottation={setShowDeliveryAnnottation}
          />
          <div className={`content-delivery-info ${collectionValue ? 'content-collection-service' : ''}`}>
            <span className="delivery-name">{conditionShowDeliveryName(deliveryName)}</span>
            <DeliveryTime deliveryTime={deliveryTime} />
            <div className="delivery-score">
              {countryCode === '170' ? (
                <>
                  <div className="routeType">
                    {routeTypeValidation(routeType)}
                  </div>
                  {
                    isExpressService && (
                      <div className="routeType-express">
                        Express
                      </div>
                    )
}
                </>
              ) : (
                <>
                  {pickupService && <PickupTag pickup={pickupService} />}
                  {officeAddress && <PickupTag pickup={false} />}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="shipping-value">
          {collectionValue > 0 && details && (
            <button
              type="button"
              className="see-more"
              onClick={(e) => handleShowDetail(e)}
            >
              {delivery && delivery.id === id ? 'Ver menos' : 'Ver más'}
              <img
                src={IconDropUp}
                className={delivery && delivery.id === id ? 'up' : 'down'}
                alt=""
              />
            </button>
          )}
          {shippingRealValue ? (
            <>
              {shippingValue === shippingRealValue ? (
                <span>
                  {toCurrencyCop(user, Math.floor(shippingValue), countryCode)}
                </span>
              ) : (
                <DeliveryValue
                  shippingValue={shippingValue}
                  shippingRealValue={shippingRealValue}
                  user={user}
                />
              )}
              {collectionValue > 0 && (
                <AmountDiscount
                  user={user}
                  realValue={realValueCollectionCommission}
                  valueDiscount={collectionCommissionValue}
                  collectionCommissionWithOutRate={collectionCommissionWithOutRate}
                />
              )}
              <div className="score">
                <img src={IconStar} alt="score-img" height="16px" />
                <p>{deliveryScore}</p>
              </div>
            </>
          ) : (
            <div className="shipping-value__mount-score">
              <span>
                {toCurrencyCop(user, Math.floor(shippingValue), countryCode)}
              </span>
              <div className="score">
                <img src={IconStar} alt="score-img" height="16px" />
                <p>{deliveryScore}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDeliveryAnnottation && (
        <DeliveryCompaniesObservations
          deliveryCompanyImgUrl={deliveryImg}
          deliveryCompanyName={deliveryName}
          deliveryCompanyId={id}
          close={() => setShowDeliveryAnnottation(false)}
          isMobileDevice={isMobileDevice}
          countryCode={user?.countryCode || countryCode}
        />
      )}
      <hr />
    </div>
  );
};

DeliveryCardToList.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  collectionValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  delivery: PropTypes.any,
  details: PropTypes.bool,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
  routeTypeValidation: PropTypes.func.isRequired,
  handleShowDetail: PropTypes.func.isRequired,
  realValueCollectionCommission: PropTypes.number,
  collectionCommissionValue: PropTypes.number,
  collectionCommissionWithOutRate: PropTypes.number,
  isExpressService: PropTypes.bool,
};

DeliveryCardToList.defaultProps = {
  collectionValue: null,
  delivery: null,
  details: true,
  shippingRealValue: null,
  officeAddress: null,
  realValueCollectionCommission: null,
  collectionCommissionValue: null,
  collectionCommissionWithOutRate: null,
  isExpressService: false,
};

export default DeliveryCardToList;
