import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Actions, Process } from '../enums';

import { typeDelivery } from '../../../../constants/general';

import Typography from '../../../../components/typography';

import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import './steps.scss';

const companiesToAddHandler = (user, object) => {
  const { deliveryCompanies } = object;
  const negotiationsSupport = user?.transportersWithNegotiation || [];
  return deliveryCompanies.filter((delivery) => !negotiationsSupport
    .find((company) => company.deliveryCompanyId === delivery._id));
};

const NoNegotiationsList = ({ object, user, setObject }) => {
  const [companiesToAdd] = useState(companiesToAddHandler(user, object));
  return (
    <div className="container-bargainings">
      <div className="principal-header">
        <img src={IconArrowBack} alt="atras" className="arrow-back" onClick={() => object.setCurrentStep(null)} />
        <Typography
          type="superTitle-italic"
          text={`Vincula una ${typeDelivery[user.countryCode ?? '170']}`}
        />
      </div>
      <div className="wrapper two">
        <Typography
          text={`Selecciona una ${typeDelivery[user.countryCode ?? '170']}`}
          type="field-gray"
          className="custom-empty-state-title"
        />
        <div className="list-of-companies-to-add">
          {companiesToAdd.map((company) => (
            <div
              className="company-row"
              key={company._id.toString()}
              onClick={() => {
                const deliverySelected = { ...company };
                setObject({ ...object, deliverySelected, action: Actions.Create });
                object.setCurrentStep(Process.CreateAndUpdateNegotiation);
              }}
            >
              <img src={company.image} alt={company.name} />
              <Typography
                text={company.name}
                type="field-gray"
                className="company-title"
              />
              <div className="next-arrow" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

NoNegotiationsList.propTypes = {
  object: PropTypes.shape().isRequired,
  setObject: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(NoNegotiationsList);
